@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --light-purple: hsl(217, 100%, 95%);
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-2: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 268 91% 57%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --success: 129, 40%, 46%;
    --success-foreground: 117, 85%, 100%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 268 91% 57%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    /* Pastel Colors */
    --pastel-blue: hsl(240, 100%, 90%);
    --pastel-red: hsl(0, 100%, 90%);
    --pastel-green: hsl(120, 100%, 90%);
    --pastel-grape: hsl(280, 100%, 90%);
  }

  .bg-pastel-blue {
    background-color: var(--pastel-blue);
  }

  .bg-pastel-red {
    background-color: var(--pastel-red);
  }

  .bg-pastel-green {
    background-color: var(--pastel-green);
  }

  .bg-pastel-grape {
    background-color: var(--pastel-grape);
  }

  .dark {
    --light-purple: hsl(217, 30%, 20%);
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;

    --card: 240 10% 8%;
    --card-foreground: 0 0% 98%;

    --card-2: 240 10% 10%;

    --popover: 240 10% 15%;
    --popover-foreground: 0 0% 98%;

    --primary: 268 91% 57%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --success: 156 85% 18%;
    --success-foreground: 156 85% 78%;

    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;

    /* Dark Mode Pastel Colors */
    --pastel-blue: hsl(240, 50%, 60%);
    --pastel-red: hsl(0, 50%, 60%);
    --pastel-green: hsl(120, 50%, 60%);
    --pastel-grape: hsl(280, 50%, 60%);
  }
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
